import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import get from 'lodash/get';
import classNames from 'classnames/bind';
import { dt } from 'yoda-core-components/lib/helpers/Utils/GetTailwindToken';
import Icon from 'yoda-core-components/lib/components/Icon/Icon';
import RewardsLogo from '../../assets/images/jcpenney_rewards_red_rgb.svg';
import * as styles from './WelcomeBanner.css';

const cx = classNames.bind(styles);

class WelcomeBanner extends PureComponent {
    static defaultProps = {
        preferences: {},
    };

    static propTypes = {
        preferences: PropTypes.object,
    };

    render() {
        const {
            preferences: { welcomeMessage },
        } = this.props;

        return (
            <section
                className={`${cx('rewardsUserWid')} ${dt([
                    'bg-white',
                    'mt-0',
                    'smMd:shadow-none',
                    'md:w-[75%]',
                    'lg:w-[65%]',
                ])}`}
            >
                <div>
                    <div
                        className={`${cx('row')} ${dt([
                            '!m-0',
                            'p-0',
                            'h-full',
                            'sm:block',
                            'md:flex',
                            'items-center',
                        ])}`}
                    >
                        <div
                            className={`${cx('rewardsUserBlock')} ${dt([
                                'w-[158px]',
                                'h-[60px]',
                                'smOnly:m-auto',
                                'align-middle',
                                'text-medium',
                                'text-black',
                                'smOnly:mb-3',
                            ])}`}
                        >
                            {/* eslint-disable-next-line */}
                            <a className={dt(['block'])} href="/rewards">
                                <Icon
                                    automationId="at-rewards-link"
                                    iconType="svg"
                                    width="158px"
                                    height="60px"
                                    viewBox="0 0 158 60"
                                    className={dt(['mr-1'])}
                                >
                                    <RewardsLogo />
                                </Icon>
                            </a>
                        </div>
                        <div
                            className={`${cx('rewardsUserBlock', 'rewardsBannerMsg')} ${dt([
                                'md:align-middle',
                                'md:ml-4',
                                'text-black',
                                'smOnly:text-center',
                                'sm:text-medium',
                                'smOnly:mb-4',
                            ])}`}
                        >
                            {get(welcomeMessage, 'newRewardsUser', '')}
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default WelcomeBanner;
